@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

@font-face {
  font-family: "Ontima-bold";
  src: url("assets/fonts/ontima/Ontima-bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Ontima";
  src: url("assets/fonts/ontima/Ontima.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 20px !important;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
  background: #006dad !important;
}
